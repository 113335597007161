.title-form-login {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.title-form-login div {
  width: 100px;
  height: 150px;
  background-color: #ccc;
}

.title-form-login h2 {
  color: var(--text-color-main);
  color: #fff;
  font-size: 50px;
}

.login-form-container {
  padding: var(--container-padding);
  position: relative;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
}

.login-form-container>h2 {
  color: var(--text-color-main);
  color: #fff;
  font-size: 40px;
  font-weight: 700;
  line-height: 60px;
  text-align: center;
  position: relative;
  top: -65px;
}

.form-group-login {
  margin-bottom: var(--form-group-margin-bottom);
}

.form-group-login label {
  color: var(--text-color-main);
  display: block;
  margin-bottom: var(--form-label-margin-bottom);
  color: #fafafa;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}

.form-input {
  border: none;
  height: 52px;
  border-radius: 20px;
  width: 100%;
  box-sizing: border-box;
  background: #c9daffa6;
}

.form-input:focus {
  outline: none;
  box-shadow: none;
  border: none;
}

.form-button {
  width: 100%;
  padding: var(--form-button-padding);
  background: #c9daff;
  color: var(--text-color-main);
  font-size: 20px;
  font-weight: 800;
  line-height: 30px;
  text-align: center;
}

.forgot-password-link,
.forgot-password-link span {
  cursor: pointer;
  color: #fff;
  font-size: 12px;
  font-style: italic;
  font-weight: 600;
  line-height: 18px;
  text-align: right;
  text-decoration: underline;
}

.forgot-password-link span:hover {
  text-decoration: underline;
}

/* Estilos para dispositivos móviles */
@media (max-width: 480px) {
  .login-form-container {
    width: calc(100% - 100px);
    padding: 0px 45px;
    position: absolute;
    top: 420px;
  }

  .form-button {
    white-space: nowrap;
    height: 52px;
    border-radius: 12px;
  }

  .title-form-login {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(0, 0);
  }

  .login-desktop {
    display: none;
  }
}

/* Estilos para tabletas */
@media (min-width: 481px) and (max-width: 1024px) {

  .title-form-login,
  .account-header,
  .login-form-container {
    display: none;
  }

  .login-desktop {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  .header-login-desktop {
    height: 53px;
    background: #f8f8f8;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 22px;
  }

  .brand-login-desktop {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0px 15px;
  }

  .brand-login-desktop>div {
    width: 46.11px;
    height: 57.63px;
    background: #1913a3;
  }

  .brand-login-desktop label {
    color: var(--text-color-main);
    font-size: 37.35px;
    font-weight: 700;
    line-height: 56.02px;
    text-align: center;
    color: var(--text-color-main);
  }

  .language-login-desktop {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0px 10px;
  }

  .language-login-desktop img {
    width: 18.88px;
    height: 18.86px;
  }

  .language-login-desktop label {
    color: var(--text-color-main);
    font-size: 20.96px;
    font-weight: 500;
    line-height: 31.44px;
    text-align: left;
    color: var(--text-color-main);
  }

  .login-image-desktop {
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    height: calc(100% - 90px);
    background: linear-gradient(rgba(57, 118, 226, 0.6), rgba(57, 118, 226, 0.6)),
      url("../../../assets/images/background.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .brand-form-desktop {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0px 15px;
    margin: 35px 0px;
  }

  .brand-form-desktop>div {
    width: 82.99px;
    height: 95.78px;
    background: #d9d9d9;
  }

  .form-desktop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 60%;
  }

  .form-desktop h2 {
    color: var(--text-color-main);
    font-size: 30.56px;
    font-weight: 700;
    line-height: 45.83px;
    text-align: center;
    color: #fafafa;
    margin: 15px 0px;
  }

  .brand-form-desktop label {
    color: var(--text-color-main);
    font-size: 40.93px;
    font-weight: 700;
    line-height: 61.4px;
    text-align: center;
    color: #fff;
  }

  .formGroup-login-desktop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 15px 0px;
  }

  .formGroup-login-desktop label {
    color: var(--text-color-main);
    font-size: 15.65px;
    font-weight: 600;
    line-height: 23.47px;
    text-align: left;
    color: #fafafa;
  }

  .formGroup-login-desktop input {
    width: 416.18px;
    height: 41.02px;
    border-radius: 15.65px;
    background: #c9daffa6;
    border: none;
  }

  .forgot-password-desktop {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
  }

  .forgot-password-desktop a {
    font-size: 12.52px;
    font-style: italic;
    font-weight: 600;
    line-height: 18.78px;
    text-align: right;
    color: #fff;
  }

  .form-desktop button {
    width: 416.18px;
    height: 41.02px;
    border-radius: 15.65px;
    background: #c9daff;
    font-size: 19.56px;
    font-weight: 600;
    line-height: 29.34px;
    text-align: center;
    color: var(--text-color-main);
    margin: 30px 0px;
  }
}

/* Estilos para escritorios */
@media (min-width: 1025px) {

  .title-form-login,
  .account-header,
  .login-form-container {
    display: none;
  }

  .login-desktop {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  .header-login-desktop {
    height: 53px;
    background: #f8f8f8;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 22px;
  }

  .brand-login-desktop {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0px 15px;
  }

  .brand-login-desktop>div {
    width: 46.11px;
    height: 57.63px;
    background: #1913a3;
  }

  .brand-login-desktop label {
    color: var(--text-color-main);
    font-size: 37.35px;
    font-weight: 700;
    line-height: 56.02px;
    text-align: center;
    color: var(--text-color-main);
  }

  .language-login-desktop {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0px 10px;
  }

  .language-login-desktop img {
    width: 18.88px;
    height: 18.86px;
  }

  .language-login-desktop label {
    color: var(--text-color-main);
    font-size: 20.96px;
    font-weight: 500;
    line-height: 31.44px;
    text-align: left;
    color: var(--text-color-main);
  }

  .login-image-desktop {
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100%;
    height: calc(100% - 97px);
    background: linear-gradient(rgba(57, 118, 226, 0.6), rgba(57, 118, 226, 0.6)),
      url("../../../assets/images/background.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
  }

  .brand-form-desktop {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 35px 0px;
  }

  .brand-form-desktop>div {
    width: 111.17px;
    height: 134.58px;
    background: #d9d9d9;
  }

  .form-desktop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-height: 40vh;
    height: 60%;
  }

  .form-desktop h2 {
    color: var(--text-color-main);
    font-size: 30px;
    font-weight: 700;
    line-height: 30px;
    text-align: center;
    color: #fafafa;
    margin: 15px 0px;
  }

  .formGroup-login-desktop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 15px 0px;
  }

  .formGroup-login-desktop label {
    color: var(--text-color-main);
    font-size: 20px;
    font-weight: 600;
    line-height: 25px;
    text-align: left;
    color: #fafafa;
  }

  .formGroup-login-desktop input {
    width: 500px;
    height: 40px;
    top: 569.01px;
    border-radius: 20.96px;
    background: #c9daffa6;
    border: none;
    padding: 0px 15px;
  }

  .formGroup-login-desktop input:hover {
    background: #c9daffd3;
  }

  .forgot-password-desktop {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
  }

  .forgot-password-desktop a {
    font-size: 14px;
    font-style: italic;
    font-weight: 600;
    line-height: 25.15px;
    text-align: right;
    color: #fff;
    text-decoration: none;
  }

  .forgot-password-desktop a:hover {
    text-decoration: underline;
  }

  .form-desktop button {
    width: 530px;
    height: 40px;
    border-radius: 20.96px;
    background: #c9daff;
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: var(--text-color-main);
    margin: 30px 0px;
    border: none;
  }
  .form-desktop button:hover {
    background: #9cbafa;
  }
}