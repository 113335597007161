.fab-button {
  position: fixed;
  bottom: 30px;
  right: 29px;
  width: 80px;
  height: 80px;
  border-radius: 20px;
  background: var(--green);
  border: none;
  box-shadow: 0px 4px 4px 0px #00000040;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fab-button img {
  width: 42.68px;
  height: 42.68px;
}

/* Estilos para tabletas */
@media (min-width: 481px) and (max-width: 1024px) {
  .fab-button {
    display: none;
  }
}

/* Estilos para escritorios */
@media (min-width: 1025px) {
  .fab-button {
    display: none;
  }
}
