.main-container-EmployeeForm {
  padding: 35px 29px;
}

.back-and-trash-EmployeeForm {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.info-EmployeeForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.info-EmployeeForm > div {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 4px solid var(--green);
  background: #d9d9d9;
  margin: 25px 0px;
}

.info-EmployeeForm input {
  font-size: 20px;
  font-weight: 800;
  line-height: 30px;
  text-align: center;
  color: var(--text-color-main);
  margin: 2px 0px;
  border: none;
  background-color: var(--background-color-main);
}

.info-EmployeeForm h4 {
  font-size: 20px;
  font-weight: 800;
  line-height: 30px;
  text-align: center;
  color: var(--text-color-main);
  margin: 2px 0px;
}

.info-EmployeeForm p {
  color: var(--text-color-main);
  font-size: 20px;
  font-weight: 800;
  line-height: 30px;
  text-align: center;
  color: #7b7b7b;
  margin-bottom: 2px;
}

.form-EmployeeForm {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-EmployeeForm div {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.form-EmployeeForm div:nth-child(2) {
  margin-top: 35px;
}

.form-EmployeeForm div:nth-child(3),
.form-EmployeeForm div:nth-child(4) {
  margin-top: 12px;
}

.form-EmployeeForm div:nth-child(5) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-EmployeeForm label {
  color: var(--text-color-main);
  grid-column: span 3;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  text-align: left;
  color: var(--text-color-main);
}

.select-no-arrow {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  pointer-events: none;
}

.form-EmployeeForm select,
.form-EmployeeForm input,
.form-EmployeeForm textarea {
  grid-column: 5 / -1;
  width: 248px;
  height: 27px;
  border: none;
  border-radius: 8px;
  background: #f1f1f1;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: var(--text-color-main);
  padding: 0px 10px;
}

.form-edit-employee select,
.form-edit-employee input,
.form-edit-employee textarea {
  background: var(--background-step);
  color: var(--text-color-input);
}

.form-EmployeeForm input,
.form-EmployeeForm textarea {
  width: 228px;
}

.form-EmployeeForm textarea {
  height: 49px;
}

.action-button-EmployeeForm {
  width: 338px;
  height: 52px;
  border: none;
  border-radius: 12px;
  background: var(--green);
  font-size: 20px;
  font-weight: 800;
  line-height: 30px;
  text-align: center;
  color: #fafafa;
}

.cancel-button-EmployeeForm {
  width: 338px;
  height: 52px;
  border-radius: 12px;
  border: 2px solid var(--green);
  font-size: 20px;
  font-weight: 800;
  line-height: 30px;
  text-align: center;
  color: var(--text-color-main);
  background: transparent;
  margin-bottom: 7px;
}
