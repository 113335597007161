.my-subscription {
    height: 50vh;
}

.subscription-page-container {
    display: flex;
    gap: 50px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.subscription-buttons-container {
    gap: 50px;
}

.subscription-buttons-container button:nth-child(1) {
    background-color: #252525;
}