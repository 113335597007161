.landing {
  background-color: var(--background-color-main);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 40px;
  height: 65px;
}

.title-landing {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.title-landing div:nth-child(1) {
  background: #1913a3;
  width: 40px;
  height: 50px;
}

.title-landing h1 {
  font-size: 24px;
  font-weight: 700;
}

.buttons-header-landing {
  display: flex;
  gap: 15px;
}

.buttons-header-landing button {
  width: 150px;
  height: 30px;
  border-radius: 10px;
  cursor: pointer;
}

.buttons-header-landing button:nth-child(2) {
  background-color: var(--background-color-main);
  border: none;
  font-size: 11px;
  font-weight: 500;
  line-height: 16.5px;
  text-align: center;
  color: var(--text-color-main);
}

.buttons-header-landing button:nth-child(3) {
  background: var(--green);
  border: none;
  color: #fafafa;
  font-size: 13.7px;
  font-weight: 600;
  line-height: 20.55px;
  text-align: center;
}

.body-landing {
  height: calc(100% - 95px);
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.main-landing {
  color: #fafafa;
  flex: 1 0 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  margin-left: 200px;
}

.main-landing button {
  background-color: #fafafa;
  color: #000;
  border: none;
  border-radius: 10px;
  font-size: 22px;
  font-weight: 600;
  padding: 10px;
  width: 200px;
  cursor: pointer;
}

.description-landing {
  flex: 0 0 auto;
  background-color: var(--background-color-main);
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  padding: 40px;
  border: var(--background-color-main) 1px solid;
}

.feature-container {
  grid-column: span 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.feature-container img {
  width: 250px;
  height: 250px;
}

.feature-container label {
  color: var(--text-color-main);
  font-size: 16px;
  font-weight: 700;
}

.feature-container p {
  color: var(--text-color-main);
  text-align: center;
  width: 210px;
}

.title-download {
  grid-column: span 12;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  font-weight: 700;
  margin: 50px 0px;
}

.brand-space {
  grid-column: span 6;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-bottom: 50px;
}

.stores-plataforms {
  grid-column: span 6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.brand-space div {
  background-color: blue;
  width: 80px;
  height: 100px;
}

.brand-space h1 {
  font-size: 45px;
}

.footer-landing {
  flex: 0 0 auto;
  background: #313131;
  color: #fafafa;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 120px;
  gap: 15px;
}

.landing-image {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--background-color-main);
  border: var(--background-color-main) 1px solid;
}

.brand-name-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0px 15px;
  margin-bottom: 20px;
}

.footer-landing p {
  color: var(--text-color-main);
  margin-bottom: 10px;
}

.footer-landing label {
  color: var(--text-color-main);
  margin-bottom: 15px;
  margin-top: 30px;
}

.brand-name-footer h1 {
  font-size: 23.93px;
  font-weight: 700;
  line-height: 35.9px;
  text-align: left;
  color: #fafafa;
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.brand-name-footer > div {
  width: 29.55px;
  height: 36.94px;
  background: var(--blue);
}

.footer-landing p {
  color: var(--text-color-main);
  font-size: 14.4px;
  font-weight: 400;
  line-height: 17.91px;
  text-align: left;
  color: #fafafa;
}

.footer-landing label {
  font-size: 14.4px;
  font-weight: 700;
  line-height: 17.91px;
  text-align: left;
  color: #fafafa;
}

.social-networks-footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 80%;
  border-top: 1px solid #474747;
  margin-top: 15px;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0px);
  padding: 20px 0px 40px;
}

.legality-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
}

.legality-options span {
  font-family: Montserrat;
  font-size: 8px;
  font-weight: 400;
  line-height: 9.75px;
  text-align: left;
  color: #ffffff;
}

.all-rights-reserved {
  background: var(--bg-black);
  width: 123%;
  height: 31px;
  font-size: 10.96px;
  font-weight: 500;
  line-height: 16.44px;
  text-align: center;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: -35px;
  left: -40px;
}

.video-background {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.background-video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 2;
  /* Asegura que el video esté detrás del texto */
  transform: translate(-50%, -50%);
}

.overlay-text {
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #2bceff;
  font-size: 2rem;
  z-index: 3;
  /* Asegura que el texto esté sobre el video */
  background: rgba(19, 71, 215, 0.5);
  border-radius: 5vh;
  padding: 10px;
}

.logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  /* Asegura que el logo esté sobre el video */
}

.soon-container {
  position: relative;
  text-align: center;
  padding: 20px;
  z-index: 3;
}

.flag-selector {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 3;
}

.flag-button {
  font-size: 1.5em;
  background: none;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.flag-button:hover {
  transform: scale(1.3);
  background: rgba(19, 71, 215, 0.5);
  border-radius: 50%;
}

.flag-menu {
  display: flex;
  position: absolute;
  top: 0;
  right: 40px;
  flex-direction: column;
  background: rgba(19, 71, 215, 0.5);
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 5px;
  animation: slideIn 0.3s ease forwards;
  z-index: 3;
}

.flag-option {
  font-size: 1.5em;
  background: none;
  border: none;
  cursor: pointer;
  margin: 5px 0;
  transition: transform 0.2s ease, opacity 0.3s ease;
}

.flag-option:hover {
  transform: scale(1.5);
}

/* Animaciones */
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(10px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.flag-button,
.flag-option {
  transition: opacity 0.3s ease;
}

/* Estilos para dispositivos móviles */
@media (max-width: 480px) {
  .description-tablet-compu {
    display: none;
  }

  .invitation-landing {
    background-color: var(--background-color-main);
    height: 200px;
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 80px;
  }

  .invitation-landing p {
    color: var(--text-color-main);
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    min-width: 100%;
    max-width: 100%;
    margin-block-start: 0em;
    margin-block-end: 0em;
  }

  .landing {
    padding: 12px 22px;
    height: 54px;
  }

  .title-landing div {
    background: var(--blue);
    width: 24.79px;
    height: 30.99px;
  }

  .title-landing h1 {
    font-size: 20.08px;
    font-weight: 700;
    line-height: 30.12px;
  }

  .buttons-header-landing button {
    width: 100.01px;
    height: 23.98px;
    border-radius: 6.85px;
  }

  .main-landing {
    margin: 0px 30px;
    color: #fafafa;
    flex: 1 0 310px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0px;
  }

  .main-landing h2 {
    color: var(--text-color-main);
    font-size: 38.67px;
    font-weight: 700;
    line-height: 48.09px;
    text-align: center;
  }

  .main-landing p {
    color: var(--text-color-main);
    font-size: 15.85px;
    font-weight: 600;
    line-height: 19.71px;
    text-align: center;
  }

  .invitation-landing {
    height: 170px;
    padding: 0px 20px;
    border: var(--background-color-main) 1px solid;
  }

  .invitation-landing p {
    color: var(--text-color-main);
    font-size: 25px;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
  }

  .landing-image img {
    width: 100%;
    height: 300px;
  }

  .feature-container {
    grid-column: span 12;
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    gap: 0px 10px;
  }

  .description-landing .feature-container:nth-child(2) {
    border-top: #407bff 1.05px solid;
    border-bottom: 1.05px solid #407bff;
  }

  .feature-container span {
    grid-column: span 5;
    text-align: center;
    font-size: 52.4px;
    font-weight: 600;
    line-height: 78.59px;
    color: var(--text-color-main);
  }

  .feature-container p {
    color: var(--text-color-main);
    grid-column: span 7;
    text-align: start;
    font-size: 13.1px;
    font-weight: 600;
    line-height: 19.65px;
    color: var(--text-color-main);
  }

  .title-download {
    color: var(--text-color-main);
    font-size: 30px;
    font-weight: 600;
    line-height: 45px;
    text-align: center;
    margin: 60px 0px 10px;
  }

  .step-one,
  .step-two,
  .step-three {
    grid-column: span 12;
    background: var(--background-step);
    width: 100%;
    height: 309.64px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px 0px;
    margin: 10px 0px;
  }

  .step-two {
    background: var(--background-step-two);
  }

  .step-one p,
  .step-two p,
  .step-three p {
    color: var(--text-color-main);
    font-size: 20.37px;
    font-weight: 600;
    line-height: 30.56px;
    text-align: center;
  }

  .step-one img {
    width: 145.13px;
    height: 170.44px;
  }

  .step-two > div {
    width: 107.29px;
    height: 131.06px;
    background: #1913a3;
  }

  .step-three img {
    width: 125.62px;
    height: 125.62px;
  }

  .section-title {
    grid-column: span 12;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 110px;
  }

  .section-title p {
    color: var(--text-color-main);
    font-size: 34.06px;
    font-weight: 600;
    line-height: 51.09px;
    text-align: center;
  }

  .section-title span {
    font-size: 15.72px;
    font-weight: 600;
    line-height: 23.58px;
    text-align: center;
    color: var(--text-color-main);
  }

  .subscription-section {
    grid-column: span 12;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 34px 0px;
    margin-top: 50px;
  }

  .subscription-section .plan-card:nth-child(1) .subscription-cost p {
    color: var(--text-color-main);
    font-size: 96.33px;
    font-weight: 700;
    line-height: 144.5px;
    text-align: left;
  }

  .plan-card {
    width: 340px;
    height: 344px;
  }

  .subscription-cost {
    background: var(--background-step-two);
    border-radius: 10.75px 10.75px 0px 0px;
    padding: 34px 28px 0px;
    color: var(--text-color-main);
  }

  .subscription-cost label,
  .subscription-cost p span {
    font-size: 20.28px;
    font-weight: 700;
    line-height: 30.42px;
    text-align: left;
  }

  .subscription-cost p {
    color: var(--text-color-main);
    font-size: 85px;
    font-weight: 700;
    line-height: 127.5px;
    text-align: left;
  }

  .get-subscription {
    background: var(--background-step-two);
    border-radius: 0px 0px 10.75px 10.75px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--text-color-main);
  }

  .get-subscription p {
    color: var(--text-color-main);
    font-size: 14.34px;
    font-weight: 500;
    line-height: 24.37px;
    text-align: left;
    padding: 15px 24px;
  }

  .get-subscription button {
    width: 222.57px;
    height: 55px;
    border: none;
    border-radius: 12.9px;
    background: #1913a3;
    font-size: 16.13px;
    font-weight: 600;
    line-height: 24.19px;
    text-align: center;
    color: #fafafa;
    margin-bottom: 15px;
  }

  .footer-landing {
    height: 565px;
    gap: 0px;
    padding: 30px 40px 40px;
  }

  .menu-header-landing,
  .inivitation-tablet-compu,
  .buttons-header-landing > div,
  .footer-landing-deskTablet,
  .main-landing button {
    display: none;
  }

  .overlay-text {
    width: 300px;
    text-align: center;
  }

  .flag-selector {
    top: 10px;
  }

  .flag-menu {
    flex-direction: row-reverse;
    right: 60px;
  }
}

/* Estilos para tabletas */
@media (min-width: 481px) and (max-width: 1024px) {
  .description-movil {
    display: none;
  }

  .invitation-landing {
    background-color: var(--background-color-main);
    padding: 45px 100px;
  }

  .inivitation-tablet-compu {
    font-size: 19.56px;
    font-weight: 600;
    line-height: 29.34px;
    text-align: left;
    color: var(--text-color-main);
  }

  .invitation-landing p:nth-child(1) {
    display: none;
  }

  .landing-image img {
    width: 400px;
    height: 250px;
  }

  .feature-container span {
    font-size: 78.23px;
    font-weight: 600;
    line-height: 117.34px;
    text-align: right;
    color: var(--text-color-main);
  }

  .step-one,
  .step-two,
  .step-three {
    grid-column: span 4;
    background: var(--background-step);
    width: 300px;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px 0px;
    margin: 10px 0px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  .step-two {
    background: var(--background-step-two);
  }

  .step-one p,
  .step-two p,
  .step-three p {
    color: var(--text-color-main);
    font-size: 20.37px;
    font-weight: 600;
    line-height: 30.56px;
    text-align: center;
  }

  .step-one img {
    width: 145.13px;
    height: 170.44px;
  }

  .step-two > div {
    width: 107.29px;
    height: 131.06px;
    background: #1913a3;
  }

  .step-three img {
    width: 125.62px;
    height: 125.62px;
  }

  .section-title {
    grid-column: span 12;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 55px;
  }

  .section-title p {
    font-size: 50.85px;
    font-weight: 600;
    line-height: 76.27px;
    text-align: center;
    color: var(--text-color-main);
  }

  .section-title span {
    font-size: 23.47px;
    font-weight: 600;
    line-height: 35.2px;
    text-align: center;
    color: var(--text-color-main);
  }

  .section-title br {
    display: none;
  }

  .subscription-section {
    grid-column: span 12;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 34px;
    margin-top: 50px;
  }

  .plan-card {
    width: 250px;
  }

  .subscription-cost {
    background: var(--background-step-two);
    border-radius: 10.75px 10.75px 0px 0px;
    padding: 34px 28px 0px;
    width: 200px;
  }

  .subscription-cost label,
  .subscription-cost p span {
    font-size: 20.28px;
    font-weight: 700;
    line-height: 30.42px;
    text-align: left;
    color: var(--text-color-main);
  }

  .subscription-cost p {
    color: var(--text-color-main);
    font-size: 55px;
    font-weight: 700;
    line-height: 127.5px;
    text-align: left;
  }

  .get-subscription {
    background: var(--background-step-two);
    border-radius: 0px 0px 10.75px 10.75px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 25px;
    height: 480px;
    width: 257px;
  }

  .get-subscription p {
    color: var(--text-color-main);
    font-size: 14.34px;
    font-weight: 500;
    line-height: 24.37px;
    text-align: left;
    padding: 15px 24px;
  }

  .get-subscription button {
    width: 239.92px;
    height: 46.07px;
    border-radius: 13.91px;
    background: #1913a3;
    font-size: 15.65px;
    font-weight: 600;
    line-height: 23.47px;
    text-align: center;
    color: #fafafa;
    margin-bottom: 15px;
    border: none;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  .all-rights-reserved {
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .landing {
    padding: 0px 20px;
    height: 97.46px;
  }

  .buttons-header-landing button:nth-child(3) {
    background: #1913a3;
    width: 150px;
    height: 36.68px;
    border-radius: 10.48px;
    font-size: 18px;
    font-weight: 600;
    line-height: 31.44px;
    text-align: center;
    color: #fafafa;
  }

  .buttons-header-landing button:nth-child(2) {
    width: 150px;
    height: 24.1px;
    font-size: 18px;
    font-weight: 500;
    line-height: 31.44px;
    text-align: center;
    color: var(--text-color-main);
  }

  .title-landing h1 {
    font-size: 30px;
    font-weight: 700;
    line-height: 56.02px;
    text-align: center;
    color: var(--text-color-main);
  }

  .menu-header-landing {
    margin-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .menu-header-landing span {
    font-size: 16px;
    font-weight: 700;
    line-height: 31.44px;
    text-align: left;
    color: var(--text-color-main);
  }

  .menu-header-landing span:hover {
    background: rgba(57, 118, 226, 0.6);
    cursor: pointer;
  }

  .buttons-header-landing > div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-right: 0px;
  }

  .buttons-header-landing > div span {
    font-size: 18px;
    font-weight: 500;
    line-height: 31.44px;
    text-align: left;
    color: var(--text-color-main);
  }

  .buttons-header-landing button:nth-child(1):hover {
    background: rgba(57, 118, 226, 0.6);
    cursor: pointer;
  }

  .main-landing {
    align-items: center;
    margin-left: 0px;
    padding: 0px 90px;
  }

  .main-landing h2 {
    font-size: 46px;
    font-weight: 700;
    line-height: 50px;
    text-align: center;
    color: #fafafa;
  }

  .main-landing br {
    display: none;
  }

  .main-landing p {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    text-align: center;
    color: #fafafa;
  }

  .main-landing button {
    width: 228.12px;
    height: 52.06px;
    top: 367.53px;
    border-radius: 14.2px;
    margin: 23px 0px;
    font-size: 23.66px;
    font-weight: 600;
    line-height: 35.5px;
    text-align: center;
    color: #1913a3;
    padding: 5px 0px;
  }

  .iamge-percentages {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .landing-image,
  .description-landing {
    grid-column: span 6;
    padding: 0px;
  }

  .description-landing .feature-container:nth-child(2) {
    border-top: 2.1px solid #407bff;
    border-bottom: 2.1px solid #407bff;
  }

  .feature-container {
    width: 400px;
    flex-direction: row;
    grid-column: span 12;
    gap: 20px;
    padding: 10px;
  }

  .feature-container p {
    width: 269.11px;
    height: 87px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 29.34px;
    text-align: left;
    color: var(--text-color-main);
  }

  .title-download {
    font-size: 50.85px;
    font-weight: 600;
    line-height: 76.27px;
    text-align: center;
    color: var(--text-color-main);
  }

  .description-tablet-compu span {
    font-size: 17.39px;
    font-weight: 700;
    line-height: 26.08px;
    text-align: left;
    color: var(--text-color-main);
  }

  .description-tablet-compu ul {
    list-style-type: disc;
  }

  .description-tablet-compu li {
    font-size: 13px;
    font-weight: 500;
    line-height: 23.64px;
    text-align: left;
    color: var(--text-color-main);
  }

  .footer-landing {
    display: none;
  }

  .footer-landing-deskTablet {
    background-color: #313131;
    border: 2.07px solid #474747;
    height: 995.51px;
    color: var(--text-color-main);
  }

  .terms-conditions-desktop {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0px 150px;
  }

  .terms-conditions-desktop p {
    color: var(--text-color-main);
    font-size: 18.56px;
    font-weight: 400;
    line-height: 22.63px;
    text-align: left;
  }

  .all-rights-reserved-desktop {
    background-color: var(--bg-black);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50.3px;
    margin-bottom: 30px;
  }

  .all-rights-reserved-desktop p {
    color: var(--text-color-main);
    font-size: 16.77px;
    font-weight: 500;
    line-height: 25.15px;
    text-align: center;
  }

  .social-network-desktop {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0px;
    gap: 100px;
  }

  .social-network-desktop img {
    width: 46.4px;
    height: 48.6px;
  }

  .company-services-desktop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .company-services-desktop label {
    font-size: 22.27px;
    font-weight: 700;
    line-height: 27.7px;
    text-align: left;
    color: #fafafa;
  }

  .company-services-desktop span {
    font-size: 22.27px;
    font-weight: 400;
    line-height: 27.7px;
    text-align: left;
    color: #fafafa;
  }

  .container-company-services {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 0px 120px;
    margin: 50px 0px;
    border-bottom: 1.55px solid #474747;
    padding-bottom: 50px;
    width: 810.5px;
  }

  .brand-name-desktop {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .brand-name-desktop > div {
    width: 55.46px;
    height: 67.13px;
    background: #1913a3;
  }

  .brand-name-desktop label {
    font-size: 35.46px;
    font-weight: 700;
    line-height: 53.19px;
    text-align: left;
    color: #fafafa;
  }

  .slogan-brand-desktop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .slogan-brand-desktop p {
    color: var(--text-color-main);
    font-size: 27.06px;
    font-weight: 400;
    line-height: 33.65px;
    text-align: left;
    margin: 20px 0px;
  }

  .footer-main-desktop {
    padding: 70px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .flag-menu {
    right: 70px;
  }

  .flag-selector {
    top: 20px;
    right: 20px;
  }

  .flag-button {
    font-size: 2.5em;
  }

  .flag-option {
    font-size: 2.5em;
  }
}

/* Estilos para escritorios */
@media (min-width: 1025px) {
  .description-movil {
    display: none;
  }

  .invitation-landing {
    background-color: var(--background-color-main);
    padding: 45px 135px;
  }

  .inivitation-tablet-compu {
    font-size: 26.2px;
    font-weight: 600;
    line-height: 39.3px;
    text-align: left;
    color: var(--text-color-main);
  }

  .invitation-landing p:nth-child(1) {
    display: none;
  }

  .landing-image img {
    width: 799.55px;
    height: 569.01px;
  }

  .feature-container span {
    font-size: 104.79px;
    font-weight: 600;
    line-height: 157.19px;
    text-align: right;
    color: var(--text-color-main);
  }

  .step-one,
  .step-two,
  .step-three {
    grid-column: span 4;
    background: var(--background-step);
    width: 477.85px;
    height: 477.85px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px 0px;
    margin: 10px 0px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  .step-two {
    background: var(--background-step-two);
  }

  .step-one p,
  .step-two p,
  .step-three p {
    color: var(--text-color-main);
    font-size: 20.37px;
    font-weight: 600;
    line-height: 30.56px;
    text-align: center;
  }

  .step-one img {
    width: 145.13px;
    height: 170.44px;
  }

  .step-two > div {
    width: 107.29px;
    height: 131.06px;
    background: #1913a3;
  }

  .step-three img {
    width: 125.62px;
    height: 125.62px;
  }

  .section-title {
    grid-column: span 12;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 110px;
  }

  .section-title p {
    color: var(--text-color-main);
    font-size: 68.11px;
    font-weight: 600;
    line-height: 102.17px;
    text-align: center;
  }

  .section-title span {
    font-size: 31.44px;
    font-weight: 600;
    line-height: 47.16px;
    text-align: center;
    color: var(--text-color-main);
  }

  .section-title br {
    display: none;
  }

  .subscription-section {
    grid-column: span 12;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 34px;
    margin-top: 50px;
  }

  .plan-card {
    width: 442.49px;
  }

  .subscription-cost {
    background: var(--background-step-two);
    border-radius: 10.75px 10.75px 0px 0px;
    padding: 34px 28px 0px;
    width: 294px;
  }

  .subscription-cost label,
  .subscription-cost p span {
    font-size: 20.28px;
    font-weight: 700;
    line-height: 30.42px;
    text-align: left;
    color: var(--text-color-main);
  }

  .subscription-cost p {
    color: var(--text-color-main);
    font-size: 85px;
    font-weight: 700;
    line-height: 127.5px;
    text-align: left;
  }

  .get-subscription {
    background: var(--background-step-two);
    border-radius: 0px 0px 10.75px 10.75px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 25px;
    height: 480px;
    width: 350px;
  }

  .get-subscription p {
    color: var(--text-color-main);
    font-size: 14.34px;
    font-weight: 500;
    line-height: 24.37px;
    text-align: left;
    padding: 15px 24px;
  }

  .get-subscription button {
    width: 239.92px;
    height: 46.07px;
    border-radius: 13.91px;
    background: #1913a3;
    font-size: 15.65px;
    font-weight: 600;
    line-height: 23.47px;
    text-align: center;
    color: #fafafa;
    margin-bottom: 15px;
    border: none;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  .all-rights-reserved {
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .landing {
    padding: 0px 20px;
    height: 97.46px;
  }

  .buttons-header-landing button:nth-child(3) {
    background: #1913a3;
    width: 152.99px;
    height: 36.68px;
    border-radius: 10.48px;
    font-size: 20.96px;
    font-weight: 600;
    line-height: 31.44px;
    text-align: center;
    color: #fafafa;
  }

  .buttons-header-landing button:nth-child(2) {
    width: 159.28px;
    height: 24.1px;
    font-size: 20.96px;
    font-weight: 500;
    line-height: 31.44px;
    text-align: center;
    color: var(--text-color-main);
  }

  .title-landing h1 {
    font-size: 37.35px;
    font-weight: 700;
    line-height: 56.02px;
    text-align: center;
    color: var(--text-color-main);
  }

  .menu-header-landing {
    margin-left: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 77.55px;
  }

  .menu-header-landing span {
    font-size: 20.96px;
    font-weight: 700;
    line-height: 31.44px;
    text-align: left;
    color: var(--text-color-main);
  }

  .buttons-header-landing > div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-right: 28.3px;
  }

  .buttons-header-landing > div span {
    font-size: 20.96px;
    font-weight: 500;
    line-height: 31.44px;
    text-align: left;
    color: var(--text-color-main);
  }

  .main-landing {
    align-items: center;
    margin-left: 0px;
    padding: 0px 135px;
  }

  .main-landing h2 {
    font-size: 77.35px;
    font-weight: 700;
    line-height: 96.17px;
    text-align: center;
    color: #fafafa;
  }

  .main-landing br {
    display: none;
  }

  .main-landing p {
    font-size: 31.7px;
    font-weight: 600;
    line-height: 39.42px;
    text-align: center;
    color: #fafafa;
  }

  .main-landing button {
    width: 305.58px;
    height: 69.74px;
    border-radius: 19.02px;
    margin: 46px 0px;
    font-size: 31.7px;
    font-weight: 600;
    line-height: 47.55px;
    text-align: center;
    color: #1913a3;
    padding: 5px 0px;
  }

  .iamge-percentages {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .landing-image,
  .description-landing {
    grid-column: span 6;
  }

  .description-landing .feature-container:nth-child(2) {
    border-top: 2.1px solid #407bff;
    border-bottom: 2.1px solid #407bff;
  }

  .feature-container {
    width: 696.86px;
    flex-direction: row;
    grid-column: span 12;
    gap: 50px;
  }

  .feature-container p {
    width: 360.48px;
    height: 117px;
    font-size: 26.2px;
    font-weight: 600;
    line-height: 39.3px;
    text-align: left;
    color: var(--text-color-main);
  }

  .title-download {
    font-size: 68.11px;
    font-weight: 600;
    line-height: 102.17px;
    text-align: center;
    color: var(--text-color-main);
  }

  .description-tablet-compu span {
    font-size: 17.39px;
    font-weight: 700;
    line-height: 26.08px;
    text-align: left;
    color: var(--text-color-main);
  }

  .description-tablet-compu ul {
    list-style-type: disc;
  }

  .description-tablet-compu li {
    font-size: 13.91px;
    font-weight: 500;
    line-height: 23.64px;
    text-align: left;
    color: var(--text-color-main);
  }

  .footer-landing {
    display: none;
  }

  .footer-landing-deskTablet {
    background-color: #313131;
    border: 2.07px solid #474747;
    height: 1100px;
    color: #fafafa;
  }

  .terms-conditions-desktop {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0px 150px;
  }

  .terms-conditions-desktop p {
    color: #fafafa;
    font-family: Montserrat;
    font-size: 24.86px;
    font-weight: 400;
    line-height: 30.31px;
    text-align: left;
  }

  .all-rights-reserved-desktop {
    background-color: var(--bg-black);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50.3px;
    margin-bottom: 30px;
  }

  .all-rights-reserved-desktop p {
    color: var(--text-color-main);
    font-size: 16.77px;
    font-weight: 500;
    line-height: 25.15px;
    text-align: center;
  }

  .social-network-desktop {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0px;
    gap: 100px;
  }

  .social-network-desktop img {
    width: 62.16px;
    height: 65.1px;
  }

  .company-services-desktop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .company-services-desktop label {
    font-size: 29.84px;
    font-weight: 700;
    line-height: 37.1px;
    text-align: left;
    color: #fafafa;
  }

  .company-services-desktop span {
    font-size: 29.84px;
    font-weight: 400;
    line-height: 37.1px;
    text-align: left;
    color: #fafafa;
  }

  .container-company-services {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 0px 120px;
    margin: 50px 0px;
    border-bottom: 1.55px solid #474747;
    padding-bottom: 50px;
    width: 810.5px;
  }

  .brand-name-desktop {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .brand-name-desktop > div {
    width: 74.29px;
    height: 89.93px;
    background: #1913a3;
  }

  .brand-name-desktop label {
    font-size: 47.5px;
    font-weight: 700;
    line-height: 71.25px;
    text-align: left;
    color: #fafafa;
  }

  .slogan-brand-desktop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .slogan-brand-desktop p {
    color: #fafafa;
    font-size: 36.25px;
    font-weight: 400;
    line-height: 45.08px;
    text-align: left;
    margin: 20px 0px;
  }

  .footer-main-desktop {
    padding: 70px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
