.login-and-security-movil {
  margin: 30px;
  background-color: #fff;
}

.list-settings-functions {
  display: flex;
  flex-direction: column;
  gap: 120px 0px;
  margin-bottom: 30px;
}

/* Estilos para escritorios */
@media (min-width: 1025px) {
  .login-and-security-movil {
    position: relative;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
  }

  .login-and-security-movil img {
    display: none;
  }

  .list-settings-functions {
    gap: 50px 0px;
  }
}