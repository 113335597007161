.feedback-message {
  padding: 10px;
  margin: 10px 0;
  border-radius: 12px;
  color: #fafafa;
  font-size: 16px;
  text-align: center;
  position: absolute;
  top: 90px;
  right: 10px;
}

.success {
  background-color: var(--green);
}

.error {
  background-color: var(--suspended-color);
}

.warning {
  background-color: #f0b323;
}

/* Estilos para dispositivos móviles */
@media (max-width: 480px) {
  .feedback-message {
    top: 50px;
    left: 0;
    right: 0;
    border-radius: 0px;
    height: 20px;
    padding: 0;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
