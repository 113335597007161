.title-registration {
  width: 100%;
  height: 116px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title-registration h2 {
  color: var(--text-color-main);
  color: #fafafa;
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  text-align: center;
}

.registration-form-container {
  background-color: #fafafa;
  height: 762px;
  overflow-y: auto;
}

.registration-form {
  max-width: 400px;
  margin: auto;
  padding: 23px 44px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  color: var(--text-color-main);
  display: block;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: var(--text-color-main);
}

.form-group input,
.form-group select {
  width: calc(100% - 7px);
  height: 36px;
  border-radius: 10px;
  border: 2px solid #c9daff;
  font-size: 14px;
  outline: none;
  transition: border-color 0.3s;
  background-color: #fafafa;
}

.form-group select {
  width: calc(102% - 7px);
}

.form-group input:focus,
.form-group select:focus {
  border-color: #407bff;
}

.input-error {
  border-color: #ff4d4f;
}

.error-message {
  color: #ff4d4f;
  font-size: 12px;
  margin-top: 5px;
}

.note {
  margin-top: 5px;
  margin-right: 15px;
  font-size: 10px;
  font-style: italic;
  font-weight: 400;
  line-height: 15px;
  text-align: right;
  color: var(--text-color-main);
  text-decoration: underline;
}

.btn-submit {
  width: calc(102% - 7px);
  height: 52px;
  padding: 10px;
  border-radius: 12px;
  background: var(--green);
  color: #fafafa;
  border: none;
  cursor: pointer;
  font-size: 20px;
  font-weight: 800;
  line-height: 30px;
  text-align: center;
  transition: background-color 0.3s;
}

.btn-submit:hover {
  background-color: #0056b3;
}

.registration-question {
  margin: 20px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
}

.btn-login {
  width: calc(102% - 7px);
  height: 52px;
  border-radius: 12px;
  border: none;
  background: #a5f3b2;
  font-size: 20px;
  font-weight: 800;
  line-height: 30px;
  text-align: center;
  color: var(--text-color-main);
  transition: color 0.3s;
}

.form-group.checkout {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;
}

.form-group.checkout label {
  color: var(--text-color-main);
  display: flex;
  align-items: center;
  color: #333;
  cursor: pointer;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  text-align: left;
}

.form-group.checkout input[type="checkbox"] {
  margin-right: 15px;
  transform: scale(1.2);
  cursor: pointer;
  width: 20px;
  height: 20px;
  border: 2px solid #c9daff;
}

.form-group.checkout p.error-message {
  color: #ff4d4f;
  font-size: 12px;
}

/* Estilos para dispositivos móviles */
@media (max-width: 480px) {
  .general-container-signup {
    display: none;
  }

  .account-header {
    width: calc(100% - 44px);
    height: 54px;
    background: var(--bg-black);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0px 10px;
    padding: 0px 22px;
  }

  .account-header > div {
    width: 24.79px;
    height: 30.99px;
    background: var(--blue);
  }

  .account-header h1 {
    font-size: 20.08px;
    font-weight: 700;
    line-height: 30.12px;
    text-align: left;
    color: #fafafa;
  }

  .containerScroll {
    max-height: 100%;
    overscroll-behavior-y: contain;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

/* Estilos para tabletas */
@media (min-width: 481px) and (max-width: 1024px) {
  .containerScroll {
    display: none;
  }

  .general-container-signup {
    width: 100%;
    height: 100%;
  }

  .header-signup {
    background: #f8f8f8;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 97.46px;
    color: var(--text-color-main);
  }

  .brand-signup,
  .language-signup {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 0px 20px;
  }

  .brand-signup > div {
    width: 46.11px;
    height: 57.63px;
    background: #1913a3;
  }

  .brand-signup label {
    color: var(--text-color-main);
    font-size: 37.35px;
    font-weight: 700;
    line-height: 56.02px;
    text-align: center;
  }

  .body-signup {
    width: 100%;
    height: calc(100% - 90px);
    display: flex;
  }

  .formulario-signup {
    background-color: #ffffff;
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--text-color-main);
    overflow-y: scroll;
  }

  .bg-image {
    width: 50%;
    height: 100%;
    background: linear-gradient(rgba(25, 19, 163, 0.6), rgba(25, 19, 163, 0.6)),
      url("../../../assets/images/background.png");
    background-size: cover;
    background-repeat: no-repeat;
  }

  .marginForm-signup {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: normal;
  }

  .formulario-signup h4 {
    font-size: 28.96px;
    font-weight: 700;
    line-height: 43.45px;
    text-align: center;
    margin-bottom: 25px;
  }

  .formGroup-signup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    margin-bottom: 15px;
  }

  .formGroup-signup label {
    color: var(--text-color-main);
    font-size: 14.83px;
    font-weight: 600;
    line-height: 22.25px;
    text-align: left;
    color: var(--text-color-main);
  }

  .formGroup-signup input {
    width: 100%;
    height: 40.79px;
    border-radius: 14.83px;
    border: 1.49px solid #407bff;
  }

  .checkbox-signup {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    margin-bottom: 25px;
  }

  .checkbox-signup input {
    width: 23px;
    height: 23px;
  }

  .checkbox-signup p {
    color: var(--text-color-main);
    font-size: 12.69px;
    font-weight: 400;
    line-height: 19.04px;
    text-align: left;
  }

  .btnAction-signup {
    width: 100%;
    height: 40.79px;
    border-radius: 14.83px;
    background: var(--green);
    color: #fafafa;
    border: none;
    font-size: 18.54px;
    font-weight: 600;
    line-height: 27.81px;
    text-align: center;
  }

  .cuestion-signup {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 0px;
  }

  .cuestion-signup span {
    font-size: 11.86px;
    font-weight: 400;
    line-height: 17.8px;
    text-align: center;
    color: #4e4e4e;
    white-space: nowrap;
    margin: 0px 20px;
  }

  .cuestion-signup div:nth-child(1),
  .cuestion-signup div:last-child {
    border: #407bff 1px solid;
    width: 28%;
  }

  .btnAction-signin {
    width: 100%;
    height: 40.79px;
    border-radius: 14.83px;
    background: #a5f3b2;
    font-size: 18.54px;
    font-weight: 600;
    line-height: 27.81px;
    text-align: center;
    border: none;
    color: var(--text-color-main);
  }
}

/* Estilos para escritorios */
@media (min-width: 1025px) {
  .containerScroll {
    display: none;
  }

  .general-container-signup {
    width: 100%;
    height: 100%;
  }

  .header-signup {
    background: #f8f8f8;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 97.46px;
    color: var(--text-color-main);
  }

  .brand-signup,
  .language-signup {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 0px 20px;
  }

  .brand-signup > div {
    width: 46.11px;
    height: 57.63px;
    background: #1913a3;
  }

  .brand-signup label {
    color: var(--text-color-main);
    font-size: 37.35px;
    font-weight: 700;
    line-height: 56.02px;
    text-align: center;
  }

  .body-signup {
    width: 100%;
    height: calc(100% - 90px);
    display: flex;
  }

  .formulario-signup {
    background-color: #ffffff;
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--text-color-main);
  }

  .bg-image {
    width: 50%;
    height: 100%;
    background: linear-gradient(rgba(25, 19, 163, 0.6), rgba(25, 19, 163, 0.6)),
      url("../../../assets/images/background.png");
    background-size: cover;
    background-repeat: no-repeat;
  }

  .marginForm-signup {
    width: 70%;
  }

  .formulario-signup h4 {
    font-size: 38.8px;
    font-weight: 700;
    line-height: 58.2px;
    text-align: center;
    margin-bottom: 25px;
  }

  .formGroup-signup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    margin-bottom: 15px;
  }

  .formGroup-signup label {
    color: var(--text-color-main);
    font-size: 19.87px;
    font-weight: 600;
    line-height: 29.8px;
    text-align: left;
  }

  .formGroup-signup input {
    width: 100%;
    height: 54.63px;
    border-radius: 19.87px;
    border: 2px solid #407bff;
  }

  .checkbox-signup {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    margin-bottom: 25px;
  }

  .checkbox-signup input {
    width: 23px;
    height: 23px;
  }

  .checkbox-signup p {
    color: var(--text-color-main);
    font-size: 17px;
    font-weight: 400;
    line-height: 25.5px;
    text-align: left;
  }

  .btnAction-signup {
    width: 100%;
    height: 54.63px;
    border-radius: 19.87px;
    background: var(--green);
    color: #fafafa;
    border: none;
    font-size: 24.83px;
    font-weight: 600;
    line-height: 37.25px;
    text-align: center;
  }

  .cuestion-signup {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 0px;
  }

  .cuestion-signup span {
    font-size: 15.89px;
    font-weight: 400;
    line-height: 23.84px;
    text-align: center;
    color: #4e4e4e;
    white-space: nowrap;
    margin: 0px 20px;
  }

  .cuestion-signup div:nth-child(1),
  .cuestion-signup div:last-child {
    border: #407bff 1px solid;
    width: 28%;
  }

  .btnAction-signin {
    width: 100%;
    height: 54.63px;
    border-radius: 19.87px;
    background: #a5f3b2;
    font-size: 24.83px;
    font-weight: 600;
    line-height: 37.25px;
    text-align: center;
    border: none;
    color: var(--text-color-main);
  }
}
