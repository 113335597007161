.advanced-configuration-movil {
  margin: 30px;
}

/* Estilos para escritorios */
@media (min-width: 1025px) {
  .advanced-configuration-movil img {
    display: none;
  }

  .advanced-configuration-movil {
    position: relative;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 71%;
  }
}