.change-photo-button-settings {
  border: #1cd93c 2px solid;
  border-radius: 14px;
  background-color: var(--background-color-white);
  color: var(--text-color-main);
  font-weight: 700;
  font-size: 16px;
  padding: 10px 15px;
  min-width: 100px;
}
