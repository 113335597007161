:root {
  --blue: #231adb;
  --blue-border: #231adb;
  --background-color-main: #fafafa;
  --background-color-white: #fff;
  --text-color-main: #252525;
  --green: #1cd93c;
  --bg-black: #252525;
  --sidebar-hover-bg-color: #f1f1f1;
  --bg-statistics-home: #f3f8ff;
  --bg-dark-gray: #f1f1f1;
  --color-gray-border: #e7e7e7;
  --background-step: #c9daff;
  --background-step-two: #eef3ff;
  --background-get-subscription: #cddcff;
  --text-color-input: #4a60c3;
  --suspended-color: #d91c3b;
  --option-box-background-color: #f8f8f8;
  --header-height: 85px;
  --header-padding: 0px 25px;
  --header-border-bottom: 1px #000 solid;
  --section-title-gap: 15px;
  --section-title-img-size: 30px;
  --section-title-font-size: 24px;
  --section-header-gap-mobile: 9px;
  --section-header-gap-desktop: 15px;
  --base-view-left-offset: 61px;
  --footer-border-top: 1px #000 solid;
  --footer-height: 45px;
  --sidebar-width: 60px;
  --sidebar-expanded-width: 190px;
  --sidebar-top: 85px;
  --sidebar-bottom-mobile: 0;
  --sidebar-bottom-tablet: 48px;
  --sidebar-bottom-desktop: 48px;
  --sidebar-bg-color: #fafafa;
  --sidebar-gap: 20px;
  --sidebar-transition: width 0.3s ease;
  --sidebar-padding: 10px 7px;
  --icon-size: 25px;
  --span-margin-left: 15px;
  --container-max-width-mobile: 300px;
  --container-max-width-desktop: 400px;
  --container-padding: 20px;
  --container-border: 1px solid #ccc;
  --container-border-radius: 10px;
  --container-max-width-mobile: 75%;
  --container-max-width-tablet-desktop: 100%;
  --container-padding: 20px;
  --container-border: 1px solid #ccc;
  --container-border-radius: 10px;
  --form-group-margin-bottom: 30px;
  --form-label-margin-bottom: 5px;
  --form-input-border-bottom: #0056b3 1px solid;
  --form-input-padding: 10px;
  --form-button-padding: 10px;
  --form-button-bg-color: #007bff;
  --form-button-hover-bg-color: #0056b3;
  --form-button-color: #fff;
  --form-button-border-radius: 10px;
  --link-text-align: right;
  --link-margin-top: 15px;
  --link-color: #fff;
}

[data-theme="dark"] {
  --blue: #1a147b;
  --blue-border: #4a4a4a;
  --background-color-main: #1e1e1e;
  --background-color-white: #333333;
  --text-color-main: #fafafa;
  --green: #18b32b;
  --bg-black: #444;
  --sidebar-hover-bg-color: #2a2a2a;
  --bg-statistics-home: #2a2f38;
  --bg-dark-gray: #2a2a2a;
  --color-gray-border: #4a4a4a;
  --background-step: #2f3e5c;
  --background-step-two: #2c3353;
  --background-get-subscription: #1f2a45;
  --text-color-input: #fafafa;
  --suspended-color: #8c1025;
  --option-box-background-color: #2e2e2e;
  --header-height: 85px;
  --header-padding: 0px 25px;
  --header-border-bottom: 1px #000 solid;
  --section-title-gap: 15px;
  --section-title-img-size: 30px;
  --section-title-font-size: 24px;
  --section-header-gap-mobile: 9px;
  --section-header-gap-desktop: 15px;
  --base-view-left-offset: 61px;
  --footer-border-top: 1px #000 solid;
  --footer-height: 45px;
  --sidebar-width: 60px;
  --sidebar-expanded-width: 190px;
  --sidebar-top: 85px;
  --sidebar-bottom-mobile: 0;
  --sidebar-bottom-tablet: 48px;
  --sidebar-bottom-desktop: 48px;
  --sidebar-bg-color: #1c1c1c;
  --sidebar-gap: 20px;
  --sidebar-transition: width 0.3s ease;
  --sidebar-padding: 10px 7px;
  --icon-size: 25px;
  --span-margin-left: 15px;
  --container-max-width-mobile: 300px;
  --container-max-width-desktop: 400px;
  --container-padding: 20px;
  --container-border: 1px solid #ccc;
  --container-border-radius: 10px;
  --container-max-width-mobile: 75%;
  --container-max-width-tablet-desktop: 100%;
  --container-padding: 20px;
  --container-border: 1px solid #ccc;
  --container-border-radius: 10px;
  --form-group-margin-bottom: 30px;
  --form-label-margin-bottom: 5px;
  --form-input-border-bottom: #0056b3 1px solid;
  --form-input-padding: 10px;
  --form-button-padding: 10px;
  --form-button-bg-color: #007bff;
  --form-button-hover-bg-color: #0056b3;
  --form-button-color: #fff;
  --form-button-border-radius: 10px;
  --link-text-align: right;
  --link-margin-top: 15px;
  --link-color: #fff;
}

* {
  font-family: "Poppins", sans-serif;
}

body {
  height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input:focus,
select:focus {
  outline: none;
  border: none;
}

h4,
h2,
p {
  margin-block-start: 0em;
  margin-block-end: 0em;
  color: var(--text-color-main);
}

button {
  cursor: pointer;
}

table {
  border-collapse: collapse !important;
}

table > thead > tr,
table tbody tr {
  border-bottom: var(--color-gray-border) 1px solid;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  background-color: var(--background-color-main);
}

.modalContent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--background-color-main);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 400px;
}

.modalContent img {
  width: 150px;
  height: 230px;
  margin-bottom: 20px;
}

.modalContent p {
  color: var(--text-color-main);
  font-size: 14.63px;
  font-weight: 500;
  line-height: 21.94px;
  text-align: center;
}

.modalContent h3 {
  margin-block-start: 0em;
  margin-block-end: 0em;
  font-size: 14.63px;
  font-weight: 700;
  line-height: 21.94px;
  text-align: center;
  color: var(--text-color-main);
}

.modalContent div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  width: 100%;
  margin: 10px 0px;
}

.modalContent div button {
  width: 142.93px;
  height: 42.55px;
  border-radius: 13.3px;
  border: none;
  font-size: 15.96px;
  font-weight: 600;
  line-height: 23.93px;
  text-align: center;
}

.modalContent div button:first-child {
  background-color: var(--green);
  color: var(--text-color-main);
}

.modalContent div button:last-child {
  background-color: var(--bg-black);
  color: #fafafa;
}

.modalOverlay {
  position: fixed;
  inset: 0px;
  background-color: rgba(50, 50, 50, 0.75);
}

/* Estilos para dispositivos móviles */
@media (max-width: 480px) {
  .view-main {
    position: absolute;
    top: 56px;
    left: 0;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    background-color: var(--background-color-main);
  }

  .modalContent {
    width: 317px;
    min-height: 370.18px;
    max-height: 410.18px;
    background-color: var(--background-color-main);
  }
}

/* Estilos para tabletas */
@media (min-width: 481px) and (max-width: 1024px) {
  .view-main {
    position: absolute;
    top: 86px;
    right: 0px;
    bottom: 46px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 30px;
    transition: left 0.3s ease;
    background-color: var(--background-color-main);
  }

  .view-main.collapsed {
    left: 60px;
  }

  .view-main.expanded {
    left: 190px;
  }

  .modalContent {
    width: 310px;
  }
}

/* Estilos para escritorios */
@media (min-width: 1025px) {
  .view-main {
    position: absolute;
    top: 86px;
    right: 0px;
    bottom: 46px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 30px;
    transition: left 0.3s ease;
    background-color: var(--background-color-white);
  }

  .view-main.collapsed {
    left: 60px;
  }

  .view-main.expanded {
    left: 190px;
  }

  .modalContent {
    width: 310px;
  }
}

.login-desktop {
  display: flex;
  overflow-y: hidden;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
}

.full-screen-view {
  overflow-y: hidden;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(57, 118, 226, 0.6), rgba(57, 118, 226, 0.6)),
    url("./assets/images/background.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
