@media (max-width: 480px) {
  .locationCompany {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .locationCompany h4 {
    font-size: 28px;
    font-weight: 600;
    line-height: 36.7px;
    text-align: left;
    color: var(--text-color-main);
    width: 100%;
    margin: 37px 0px;
    padding-left: 46px;
  }

  .form-location-company {
    margin: 55px 0px;
  }

  .buttons-location-company {
    margin-bottom: 50px;
  }
}
