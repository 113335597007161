.section-sidebar {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  gap: var(--sidebar-gap);
  width: var(--sidebar-width);
  position: fixed;
  top: var(--sidebar-top);
  left: 0;
  overflow: hidden;
  transition: var(--sidebar-transition);
  background-color: var(--sidebar-bg-color);
}

.section-sidebar-nav {
  display: flex;
  flex-direction: column;
  border-top: 1px solid var(--color-gray-border);
}

.section-sidebar-paper {
  border-top: 1px solid var(--color-gray-border);
}

.section-sidebar img {
  width: var(--icon-size);
  height: var(--icon-size);
}

.section-sidebar span {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  color: var(--text-color-main);
}

.show-sidebar .section-sidebar {
  width: var(--sidebar-expanded-width);
}

.main-sidebar-section,
.section-sidebar-paper {
  width: 100%;
}

.sidebar-brand-logo {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 0px 10px;
}

.sidebar-brand-logo > div {
  width: 35.31px;
  height: 44.13px;
  background: var(--blue);
}

.sidebar-brand-logo h1 {
  font-size: 28.6px;
  font-weight: 700;
  line-height: 42.9px;
  text-align: left;
  color: var(--text-color-main);
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.business-profile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 44.13px;
}

.business-profile-header button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  width: 20px;
  height: 20px;
}

.business-profile-header button img {
  width: 20px;
  height: 20px;
}

.company-logo-name {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  margin: 67px 0px 16.5px;
}

.company-logo-name > div {
  width: 48px;
  height: 48px;
  background: var(--blue);
  border-radius: 50%;
}

.company-logo-name p {
  color: var(--text-color-main);
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  color: var(--text-color-main);
}

.section-sidebar-options div,
.section-sidebar-nav div,
.section-sidebar-paper div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0px 20px;
  padding: 10px 0px;
}

.section-sidebar-options {
  padding: 16.5px 0px 26.5px;
}

.section-sidebar-nav {
  padding: 26.5px 0px;
}

.section-sidebar-options div:nth-child(2) {
  display: flex;
  justify-content: space-between;
  padding: 0;
}

.section-sidebar-options div:nth-child(2) p {
  color: var(--text-color-main);
  width: 22px;
  height: 22px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--green);
  color: #fafafa;
  font-size: 12px;
  font-weight: 800;
  line-height: 18px;
  text-align: center;
}

/* Estilos específicos para dispositivos móviles */
@media (max-width: 480px) {
  .section-sidebar {
    width: 0px;
    top: 0;
    bottom: var(--sidebar-bottom-mobile);
  }

  .show-sidebar .section-sidebar {
    width: 351px;
    padding: 33px 46px 113px 33px;
    z-index: 2;
  }
}

/* Estilos específicos para tabletas */
@media (min-width: 481px) and (max-width: 1024px) {
  .section-sidebar {
    bottom: 48px;
  }

  .show-sidebar .section-sidebar {
    width: 280px;
    padding: 0px 10px 30px;
  }

  .sidebar-brand-logo {
    display: none;
  }

  .business-profile-header {
    justify-content: flex-end;
    margin: 0px 20px;
  }

  .company-logo-name {
    margin: 16.5px 7px;
  }

  .section-sidebar-options {
    padding: 16.5px 18px 26.5px;
  }

  .section-sidebar-nav {
    padding: 26.5px 18px 26.5px;
  }

  .section-sidebar-paper {
    padding: 10px 18px;
  }

  .company-logo-name > div {
    width: 48px;
    min-width: 48px;
    max-width: 48px;
    height: 48px;
    min-height: 48px;
    max-height: 48px;
  }

  .business-profile-header,
  .company-logo-name,
  .section-sidebar-options {
    display: none;
  }

  .section-sidebar-nav div,
  .section-sidebar-paper div {
    height: 20px;
  }
}

/* Estilos específicos para escritorios */
@media (min-width: 1025px) {
  .section-sidebar {
    bottom: var(--sidebar-bottom-desktop);
  }

  .show-sidebar .section-sidebar {
    width: 280px;
    padding: 0px 10px 30px;
  }

  .sidebar-brand-logo {
    display: none;
  }

  .business-profile-header {
    justify-content: flex-end;
    margin: 0px 20px;
  }

  .company-logo-name {
    margin: 16.5px 7px;
  }

  .section-sidebar-options {
    padding: 16.5px 18px 26.5px;
  }

  .section-sidebar-nav {
    padding: 26.5px 9px 26.5px;
    gap: 10px;
  }

  .section-sidebar-nav img {
    padding: 10px;
  }

  .section-sidebar-nav img:hover {
    background-color: #cfcdcd;
    border-radius: 12px;
    cursor: pointer;
  }

  .section-sidebar-paper {
    padding: 10px 8px;
  }

  .section-sidebar-paper img {
    padding: 10px;
  }

  .section-sidebar-paper img:hover {
    background-color: #cfcdcd;
    border-radius: 12px;
    cursor: pointer;
  }

  .company-logo-name > div {
    width: 48px;
    min-width: 48px;
    max-width: 48px;
    height: 48px;
    min-height: 48px;
    max-height: 48px;
  }

  .business-profile-header,
  .company-logo-name,
  .section-sidebar-options {
    display: none;
  }

  .section-sidebar-nav div,
  .section-sidebar-paper div {
    height: 20px;
  }
}
