.general-container-profile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 85px);
}

.profile-general-information {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  grid-template-rows: auto;
}

.profile-picture {
  grid-column: span 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.profile-picture > div {
  background-color: blue;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
}

.profile-picture > div img {
  width: 100px;
  height: 100px;
}

.profile-picture button#change,
.profile-information-data button,
.session-profile-security button,
.delete-account button {
  background-color: var(--background-color-main);
  border: #c8daff 2px solid;
  border-radius: 10px;
  font-weight: 600;
  min-width: 72px;
  padding: 10px 15px;
}

.profile-picture button#change {
  margin-top: 25px;
}

.profile-picture button#disguise {
  background-color: var(--background-color-main);
  border: none;
  padding: 0px;
  font-weight: 600;
}

.profile-information {
  grid-column: span 9;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.profile-information-data,
.session-profile-security {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profile-information-data span,
.delete-account p {
  color: var(--text-color-main);
  font-weight: 500;
}

.delete-account {
  position: relative;
}

.delete-account button {
  position: absolute;
  right: 0;
  width: 150px;
}

.button-container-to-change-profile-picture {
  display: flex;
  gap: 10px;
}

.edit-profile-button-container {
  display: flex;
  gap: 15px;
}

.input-container-edit-profile input,
.input-container-edit-profile select {
  font-weight: 400;
  font-size: 22px;
  line-height: 35px;
  text-align: left;
  color: var(--text-color-main);
  border: none;
  border-bottom: 1px var(--text-color-main) solid;
  width: 150%;
  background-color: var(--background-color-white);
}

/* Estilos para dispositivos móviles */
@media (max-width: 480px) {
  .deskSubs-general {
    display: none;
  }

  .general-container-profile {
    margin: 30px;
    align-items: center;
    text-align: center;
    overflow-x: hidden;
  }

  .profile-general-information {
    gap: 50px 0px;
    margin-bottom: 55px;
  }

  .profile-picture {
    grid-column: span 12;
  }

  .location-payment {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: #f0f0f0;
    width: 338px;
    height: 56px;
    border-radius: 10px;
    margin-bottom: 20px;
  }

  .location-payment h4 {
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
    text-align: left;
    color: var(--text-color-main);
  }

  .location-payment p {
    color: var(--text-color-main);
    font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
    text-align: left;
    color: var(--text-color-main);
  }

  .form-profile {
    border-bottom: #c8daff 1px solid;
    padding-bottom: 18px;
    margin-bottom: 36px;
  }

  .other-data {
    margin-bottom: 80px;
  }

  .navigation-option-profile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .browser-tag-container {
    width: 100%;
    margin: 50px 0px 25px;
  }
}

/* Estilos para tabletas */
@media (min-width: 481px) and (max-width: 1024px) {
}

/* Estilos para escritorios */
@media (min-width: 1025px) {
  .general-container-profile {
    display: none;
  }

  .photo-button-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .blue-camera-icon-container {
    background-color: #407bff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 200px;
    height: 200px;
    margin: 20px 0px;
  }

  .data-container-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
  }

  .company-data-list-container {
    margin: 100px 230px 50px 200px;
  }

  .container-with-grey-edges {
    border-top: #eeeeee 2px solid;
    border-bottom: #eeeeee 2px solid;
  }

  .advanced-options-container {
    margin: 100px 250px 30px 215px;
  }
}
