.settings-function {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.settings-function section {
  padding-right: 25px;
}

.settings-function label {
  color: var(--text-color-main);
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
  color: var(--text-color-main);
}

.settings-function p {
  color: var(--text-color-main);
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  color: var(--text-color-main);
  margin: 10px 0px 20px;
}

.payment-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border: 3.81px solid var(--green);
  border-radius: 31.32px;
  padding: 20px;
  margin-bottom: 30px;
}

.icon-labels-card {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0px 20px;
}

.label-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.label-card > label {
  color: var(--text-color-main);
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
  color: var(--text-color-main);
}

.data-card-label {
  display: flex;
  gap: 0px 15px;
}

.data-card-label label {
  color: var(--text-color-main);
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
  color: var(--text-color-main);
}

.buttons-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 0px 15px;
  margin-top: 20px;
}

.buttons-card button {
  border: none;
  width: 160px;
  height: 45px;
  border-radius: 18px;
  font-size: 20px;
  font-weight: 800;
  line-height: 30px;
  text-align: center;
}

.buttons-card button:nth-child(1) {
  background-color: var(--bg-black);
  color: #fafafa;
}

.buttons-card button:nth-child(2) {
  background-color: var(--green);
  color: var(--text-color-main);
}

/* Estilos para escritorios */
@media (min-width: 1025px) {
  .settings-function {
    justify-content: flex-start;
    align-items: flex-start;
  }

  .button-container-web {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-top: 20px;
  }

  .payment-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 2px solid #90c4e1;
    border-radius: 18px;
    padding: 10px 15px;
    margin-bottom: 30px;
    width: 100%;
  }

  .buttons-card {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    gap: 0px 15px;
    margin-top: 0px;
  }
}
