.inputMobileSubs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.inputMobileSubs label {
  color: var(--text-color-main);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: var(--text-color-main);
}

.inputMobileSubs input {
  width: 338px;
  height: 48px;
  border-radius: 20px;
  background: var(--background-step);
  border: none;
  margin-bottom: 18px;
}
