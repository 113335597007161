.payment-history-item,
.icon-description-history-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.payment-history-item {
  padding: 20px 5px;
  border-bottom: 1.77px solid var(--blue-border);
}

.icon-description-history-item {
  gap: 0px 20px;
}

.description-history-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.description-history-item label:nth-child(1) {
  font-weight: 500;
  font-size: 18px;
  color: var(--text-color-main);
}

.description-history-item label:nth-child(2) {
  font-weight: 400;
  font-size: 16px;
  color: var(--text-color-main);
}
