.billing-information {
  margin: 30px;
}

.attach-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 30px;
}

.attach-section > label {
  color: var(--text-color-main);
  font-size: 20px;
  font-weight: 600;
  line-height: 40px;
  text-align: left;
  color: var(--text-color-main);
}

.attach-section button {
  background-color: var(--green);
  color: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
  width: 250px;
  height: 50px;
  border-radius: 28.28px;
  gap: 10px;
  margin: 15px 0px;
}
