.my-payments {
  margin: 30px;
}

/* Estilos para escritorios */
@media (min-width: 1025px) {
  .my-payments > img {
    display: none;
  }
  .payment-history-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 60px;
    text-align: left;
    color: var(--text-color-main);
  }

  .billing-section-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .table-cells {
    padding: 30px;
  }

  .payment-history-table {
    text-align: center;
    color: var(--text-color-main);
  }

  .payment-history-table tbody tr:hover {
    background-color: #dfdede;
  }

  .paytable-button {
    border: none;
    width: 160px;
    height: 45px;
    border-radius: 18px;
    font-size: 20px;
    font-weight: 800;
    line-height: 30px;
    text-align: center;
    background-color: var(--bg-black);
    color: #fafafa;
  }
}

/* Estilos para tabletas */
@media (min-width: 481px) and (max-width: 1024px) {
  .table-cells {
    padding: 15px 10px;
  }

  .payment-history-table {
    text-align: center;
    color: var(--text-color-main);
  }
}
