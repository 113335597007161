.view-subscription-payment-history {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.payment-history-view-title {
  color: var(--text-color-main);
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 30px;
  width: 70%;
}

.paytable-button-download {
  border: none;
  width: 160px;
  height: 45px;
  border-radius: 18px;
  font-size: 16px;
  font-weight: 600;
  line-height: 30px;
  text-align: center;
  background-color: var(--green);
  color: var(--text-color-main);
  padding: 8px;
}

.paytable-button-download:hover {
  background-color: #252525;
  color: #fafafa;
}

.error-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.error-state label {
  font-size: 18px;
  font-weight: 500;
  color: #252525;
}

@media (max-width: 480px) {
  .view-subscription-payment-history {
    display: none;
  }

  .mobile-payment-history-view {
    padding: 30px;
  }

  .mobile-payment-list {
    margin: 20px 0px;
    padding: 0px 5px;
  }

  .mobile-payment-list > *:first-child {
    border-top: none;
  }
}

/* Estilos para tabletas */
@media (min-width: 481px) and (max-width: 1024px) {
  .mobile-payment-history-view {
    display: none;
  }
}

/* Estilos para escritorios */
@media (min-width: 1025px) {
  .mobile-payment-history-view {
    display: none;
  }
}
