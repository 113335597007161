.home-welcome,
.quick-actions,
.summary-statistics {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home-welcome h2,
.home-welcome p {
  color: var(--text-color-main);
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.home-welcome h2 {
  color: var(--text-color-main);
  font-size: 40px;
  font-weight: 700;
}

.home-welcome p {
  color: var(--text-color-main);
  font-size: 20px;
  font-weight: 600;
  margin: 7px 0px 30px;
}

.home-welcome button {
  border-radius: 16px;
  background-color: var(--green);
  color: #fff;
  padding: 12px;
  border: none;
  width: 300px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.home-welcome button:hover {
  background-color: #2aa83f;
  color: #fafafa;
}

.quick-actions p {
  color: var(--text-color-main);
  font-size: 20px;
  font-weight: 600;
  margin: 50px 0px 15px;
}

.quick-actions div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
}

.quick-actions button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  border: #1cd93c 3px solid;
  border-radius: 16px;
  background-color: #fff;
  padding: 10px 15px;
  width: 210px;
  cursor: pointer;
}

.quick-actions button:hover {
  background-color: #1cd93c;
}

.quick-actions button img {
  width: 25px;
  height: 25px;
}

.quick-actions button label {
  color: var(--text-color-main);
  font-size: 14px;
  font-weight: 600;
}

.summary-statistics {
  margin-top: 50px;
}

.statistics-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.statistics-head label {
  color: var(--text-color-main);
  font-size: 20px;
  font-weight: 700;
}

.statistics-head span {
  font-size: 16px;
  font-weight: 400;
  color: rgb(145, 145, 145);
}

.statistics-info {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 20px;
  width: 100%;
  height: 37.5vh;
}

.statistics-info div {
  background-color: var(--bg-statistics-home);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
}

.statistics-info div:nth-child(1) {
  grid-column: span 4;
}

.statistics-info div:nth-child(2) {
  grid-column: span 8;
}

.statistics-info-movil {
  display: none;
}

/* Estilos para dispositivos móviles */
@media (max-width: 480px) {
  .home-welcome {
    padding: 35px 29px 0px;
  }

  .quick-actions {
    padding: 30px 29px;
    align-items: flex-start;
  }

  .summary-statistics {
    padding: 0px 29px 35px;
  }

  .home-welcome h2 {
    color: var(--text-color-main);
    font-size: 38.67px;
    font-weight: 700;
    line-height: 48.08px;
    text-align: center;
    color: var(--text-color-main);
  }

  .home-welcome p {
    color: var(--text-color-main);
    font-size: 15px;
    font-weight: 600;
    line-height: 18.65px;
    text-align: center;
    margin: 7px 0px 30px;
    color: var(--text-color-main);
  }

  .home-welcome button {
    width: 247.74px;
    height: 38.02px;
    border-radius: 12.59px;
    border: none;
    background: var(--green);
    font-size: 15.73px;
    font-weight: 600;
    line-height: 23.6px;
    text-align: center;
    color: #fafafa;
    cursor: pointer;
    padding: 0px;
  }

  .quick-actions p,
  .statistics-head label {
    color: var(--text-color-main);
    font-size: 16px;
    font-weight: 600;
    line-height: 19.89px;
    text-align: left;
    color: var(--text-color-main);
    margin: 0px;
  }

  .quick-actions div {
    width: 100%;
    margin-top: 12px;
    gap: 0px;
    justify-content: space-around;
  }

  .quick-actions button {
    flex-direction: column;
    justify-content: center;
    gap: 0px;
    border: 2px solid var(--green);
    border-radius: 11.66px;
    color: var(--text-color-main);
    width: 84px;
    height: 57px;
    cursor: pointer;
  }

  .quick-actions button img {
    width: 20px;
    height: 20px;
  }

  .quick-actions button label {
    color: var(--text-color-main);
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
    color: var(--text-color-main);
  }

  .statistics-head span {
    display: none;
  }

  .summary-statistics {
    margin: 0px;
  }

  .statistics-info {
    display: none;
  }

  .statistics-info-movil {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    gap: 14px;
  }

  .statistics-info-movil div {
    height: 175px;
    background: #f3f7ff;
    font-size: 10px;
    font-weight: 600;
    line-height: 12.43px;
    text-align: center;
    color: #407bff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .statistics-info-movil div:nth-child(1) {
    grid-column: span 12;
  }

  .statistics-info-movil div:nth-child(2) {
    grid-column: span 4;
    padding: 0px 14px;
  }

  .statistics-info-movil div:nth-child(3) {
    grid-column: span 8;
  }
}

/* Estilos para tabletas */
@media (min-width: 481px) and (max-width: 1024px) {
}

/* Estilos para escritorios */
@media (min-width: 1025px) {
}
