.toggle-container {
    width: 50px;
    height: 25px;
    background-color: #ddd;
    border-radius: 50px;
    display: flex;
    align-items: center;
    padding: 2px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.toggle-container.on {
    background-color: #1cd93c;
}

.toggle-container.off {
    background-color: #ccc;
}

.toggle-container.off:hover {
    background-color: #8f8e8e;
}

.toggle-circle {
    width: 21px;
    height: 21px;
    background-color: white;
    border-radius: 50%;
    transition: transform 0.3s ease;
}

.toggle-container.on .toggle-circle {
    transform: translateX(25px);
}

.toggle-container.off .toggle-circle {
    transform: translateX(0);
}