.forgot-password-form-container {
  padding: var(--container-padding);
  border: var(--container-border);
  border-radius: var(--container-border-radius);
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.forgot-password-form-container h2 {
  font-size: 30px;
  font-weight: 700;
  line-height: 30px;
  text-align: center;
  color: #fafafa;
  margin: 15px 0px;
}

.form-input-forgot-pasword {
  width: 90%;
  height: 40px;
  border-radius: 20.96px;
  background: #c9daffa6;
  border: none;
  padding: 0px 15px;
}

.form-input-forgot-pasword:hover {
  background: #c9daffd3;
}

.form-button-forgot-password {
  width: 97%;
  height: 40px;
  border-radius: 20.96px;
  background: #c9daff;
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: var(--text-color-main);
  margin: 30px 0px;
  border: none;
}

.form-button-forgot-password:hover {
  background: #9cbafa;
}

.form-label-forgot-password {
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  text-align: left;
  color: #fafafa;
}

.login-link {
  font-size: 14px;
  font-style: italic;
  font-weight: 600;
  line-height: 25.15px;
  text-align: right;
  color: #fff;
  text-decoration: none;
}

.login-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

/* Estilos para dispositivos móviles */
@media (max-width: 480px) {
  .forgot-password-form-container {
    max-width: var(--container-max-width-mobile);
  }
}

/* Estilos para tabletas */
@media (min-width: 481px) and (max-width: 1024px) {
  .forgot-password-form-container {
    max-width: var(--container-max-width-mobile);
  }
}

/* Estilos para escritorios */
@media (min-width: 1025px) {
  .forgot-password-form-container {
    max-width: var(--container-max-width-desktop);
  }
}