.plataform-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--header-height);
  padding: var(--header-padding);
  gap: var(--section-title-gap);
  background-color: var(--blue);
}

.section-title-navbar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: var(--section-title-gap);
  white-space: nowrap;
}

.section-title-navbar div {
  background-color: #fafafa;
  width: 49.6px;
  height: 62px;
  cursor: pointer;
}

.section-title-navbar img,
.section-header img {
  width: var(--section-title-img-size);
  height: var(--section-title-img-size);
  cursor: pointer;
}

.section-title-navbar h1 {
  font-size: 40.17px;
  font-weight: 700;
  text-align: left;
  color: #fafafa;
}

.section-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-header img:last-child {
  width: 11.34px;
  height: 6.76px;
  position: relative;
  right: 7px;
}

.photo-profile {
  border-radius: 50%;
  background-color: lightgray;
  width: var(--section-title-img-size);
  height: var(--section-title-img-size);
}

.list-options-profile {
  width: 225px;
  height: 145px;
  position: absolute;
  top: 100px;
  right: 40px;
  display: flex;
  flex-direction: column;
  background-color: var(--bg-dark-gray);
  border-radius: 18px;
  padding: 10px 15px;
}

.item-options-profile {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  padding: 5px;
}

.item-options-profile:hover {
  background-color: rgb(230, 230, 230);
}

.list-options-profile div:nth-child(2) {
  border-bottom: rgb(187, 187, 187) 1px solid;
  border-top: rgb(187, 187, 187) 1px solid;
}

.list-options-profile div:nth-child(2):hover {
  background-color: var(--bg-dark-gray);
}

.item-options-profile img {
  width: 25px;
  height: 25px;
  grid-column: span 2;
}

.item-options-profile label {
  color: var(--text-color-main);
  grid-column: span 8;
  font-weight: 600;
}

.item-options-profile span {
  grid-column: span 2;
  position: relative;
  right: -15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: lightgreen;
  border-radius: 50%;
  color: #fff;
  width: 20px;
  height: 20px;
  font-weight: bold;
  font-size: 12px;
}

.profile-header {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.white-logo-container {
  background-color: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

/* Estilos para dispositivos móviles */
@media (max-width: 480px) {
  .plataform-header {
    height: 56px;
    background: var(--background-color-main);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }

  .section-title-navbar {
    flex-direction: row;
  }

  .section-title-navbar div {
    width: 24px;
    height: 28px;
    background: #fafafa;
  }

  .section-title-navbar h1 {
    display: none;
  }

  .breadcrumb-item.active {
    color: var(--text-color-main) !important;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    text-align: center;
  }

  .section-header img {
    display: none;
  }

  .photo-profile {
    width: 32px;
    height: 32px;
    background: var(--bg-black);
  }

  .iconTablet {
    display: none;
  }
}

/* Estilos para tabletas */
@media (min-width: 481px) and (max-width: 1024px) {
  .section-header {
    gap: 30px;
  }

  .section-title-navbar h1 {
    margin-right: 50px;
    font-size: 30px;
  }

  .section-title-navbar div {
    width: 37.03px;
    height: 46.29px;
  }
}

/* Estilos para escritorios */
@media (min-width: 1025px) {
  .section-header {
    gap: var(--section-header-gap-desktop);
  }

  .iconTablet {
    display: none;
  }
}
