.browser-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.browser-label label {
  color: var(--text-color-main);
  font-size: 18px;
  font-weight: 600;
  line-height: 60px;
  text-align: left;
  color: var(--text-color-main);
}

/* Estilos para dispositivos móviles */
@media (max-width: 480px) {
}

/* Estilos para escritorios */
@media (min-width: 1025px) {
  .browser-label label {
    color: var(--text-color-main);
    font-size: 20px;
  }
}
