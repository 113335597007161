.trips-rules-container {
    max-width: 1200px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.trips-rules-list-container {
    width: 100%;
}

.trips-rules-list-container div:nth-child(1) {
    border-top: none;
}

.rule-container {
    display: flex;
    justify-content: space-between;
    border-top: 1px #252525 solid;
    max-width: 1000px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    padding: 25px 15px;
}

.rule-container:hover {
    background-color: #fafafa;
}

.save-rules-button-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin: 35px 0px;
}