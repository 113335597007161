.container-main {
  display: flex;
  flex: 1;
  transition: margin-left 0.3s ease;
  margin-left: 0;
}

.show-sidebar .container-main {
  margin-left: 20%;
}

@media (max-width: 480px) {
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
}

/* Estilos para tabletas */
@media (min-width: 481px) and (max-width: 1024px) {
}

/* Estilos para escritorios */
@media (min-width: 1025px) {
}
