.mobile-item-for-payment-history {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: var(--blue) 2px solid;
  padding: 20px 0px;
  color: var(--text-color-main);
}

.name-and-date-of-payment-on-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.mobile-payment-data {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
