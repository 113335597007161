.settings-section-title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0px 35px;
}

.settings-section-title label {
  color: var(--text-color-main);
  font-size: 28px;
  font-weight: 600;
  line-height: 40px;
  text-align: left;
  color: var(--text-color-main);
}
