/* Estilos para dispositivos móviles */
@media (max-width: 480px) {
  .containerMainSubs {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .containerMainSubs h4 {
    font-size: 28px;
    font-weight: 600;
    line-height: 36.7px;
    text-align: left;
    color: var(--text-color-main);
    width: 100%;
    margin: 37px 0px;
    padding-left: 46px;
  }

  .form-subs {
    margin-bottom: 150px;
  }

  .deskSubs-subsGeneral {
    display: none;
  }

  .mobile-payment-method-title-container {
    margin: 20px 0px 30px;
  }
}

/* Estilos para tabletas */
@media (min-width: 481px) and (max-width: 1024px) {
  .containerMainSubs {
    display: none;
  }

  .deskSubs-subsGeneral {
    width: 800px;
    position: relative;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -20%);
  }

  .deskSubs-subsGeneral h4 {
    font-size: 40px;
    font-weight: 600;
    line-height: 78.94px;
    text-align: center;
    color: var(--text-color-main);
    margin: 40px 0px;
  }

  .gridSelect-pay,
  .cardInformation {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    gap: 0px 50px;
    margin-bottom: 60px;
  }

  .gridSelect-pay > *,
  .cardInformation > * {
    grid-column: span 7;
  }

  .buttonsPay {
    margin: 30px 0px;
  }
}

/* Estilos para escritorios */
@media (min-width: 1025px) {
  .containerMainSubs {
    display: none;
  }

  .deskSubs-subsGeneral {
    position: relative;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .deskSubs-subsGeneral h4 {
    font-size: 24px;
    font-weight: 600;
    line-height: 78.94px;
    color: var(--text-color-main);
    margin-bottom: 40px;
  }

  .gridSelect-pay,
  .cardInformation {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    gap: 0px 50px;
    margin-bottom: 60px;
  }

  .gridSelect-pay > *,
  .cardInformation > * {
    grid-column: span 7;
  }

  .buttonsPay {
    margin: 30px 0px;
  }
}
