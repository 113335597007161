/* Estilos para dispositivos móviles */
@media (max-width: 480px) {
  .main-plans {
    padding: 35px 29px;
  }

  .go-back-plans {
    width: 25px;
  }

  .text-plans {
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    text-align: center;
    margin-top: 25px;
    padding: 0px 3px;
    color: var(--text-color-main);
  }

  .plans-text,
  .go-back-plans {
    display: none;
  }
}

/* Estilos para tabletas */
@media (min-width: 481px) and (max-width: 1024px) {
  .text-plans,
  .go-back-plans {
    display: none;
  }

  .plans-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .plans-text label {
    color: var(--text-color-main);
    font-size: 52.63px;
    font-weight: 600;
    line-height: 78.94px;
    text-align: center;
    color: var(--text-color-main);
  }

  .plans-text p {
    color: var(--text-color-main);
    font-size: 24.29px;
    font-weight: 600;
    line-height: 36.44px;
    text-align: center;
    color: var(--text-color-main);
  }
}

/* Estilos para escritorios */
@media (min-width: 1025px) {
  .text-plans,
  .go-back-plans {
    display: none;
  }

  .plans-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .plans-text label {
    color: var(--text-color-main);
    font-size: 52.63px;
    font-weight: 600;
    line-height: 78.94px;
    text-align: center;
    color: var(--text-color-main);
  }

  .plans-text p {
    color: var(--text-color-main);
    font-size: 24.29px;
    font-weight: 600;
    line-height: 36.44px;
    text-align: center;
    color: var(--text-color-main);
  }
}
