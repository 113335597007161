.back-button-and-view-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.back-button-and-view-title label {
  font-size: 22px;
  font-weight: 600;
  color: var(--text-color-main);
}
