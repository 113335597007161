.item-show-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 20px;
}

.item-show-data label {
  font-size: 22px;
  line-height: 35px;
  text-align: left;
  color: var(--text-color-main);
}

.item-show-data label:nth-child(1) {
  font-weight: 600;
}

.item-show-data label:nth-child(2) {
  font-weight: 400;
}