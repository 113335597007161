.breadcrumb {
  display: flex;
  list-style: none;
  padding: 0;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: ">";
  padding: 0 8px;
  color: #fafafa;
}

.breadcrumb-item a {
  text-decoration: none;
  font-weight: 700;
}

.breadcrumb-item a:hover {
  text-decoration: underline;
}

.breadcrumb-item.active {
  color: #fafafa;
  font-weight: 700;
}

/* Estilos para dispositivos móviles */
@media (max-width: 480px) {
  .breadcrumb-item:not(.active) {
    display: none;
  }

  .breadcrumb-item + .breadcrumb-item::before {
    display: none;
  }

  .breadcrumb-item.active {
    color: var(--text-color-main);
  }
}

/* Estilos para tabletas */
@media (min-width: 481px) and (max-width: 1024px) {
  .breadcrumb-item a:visited {
    color: #fafafa;
  }
}

/* Estilos para escritorios */
@media (min-width: 1025px) {
  .breadcrumb-item:not(.active) {
    color: #fafafa !important;
  }

  .breadcrumb-item a:visited {
    color: #fafafa;
  }

  .breadcrumb-item + .breadcrumb-item::before {
    color: #fafafa;
  }
}
