.employees-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 0px;
}

.employees-button-container div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.employees-button-container div img,
.employees-table-container span img,
.employee-options div img {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.employees-button-container div:last-child img {
  width: 36.14px;
  height: 36.14px;
}

.employees-button-container div:nth-child(1) {
  gap: 15px;
}

.employees-button-container div:nth-child(2) {
  gap: 5px;
  cursor: pointer;
}

.employees-button-container div:nth-child(2) label {
  color: var(--text-color-main);
  color: var(--green);
  font-size: 18.07px;
  font-weight: 800;
  line-height: 27.1px;
  text-align: center;
  cursor: pointer;
}

.employees-table-container table {
  width: 100%;
}

.employees-table-container th,
.employees-table-container td {
  height: 65px;
}

.employees-table-container th {
  font-size: 15.1px;
  font-weight: 700;
  line-height: 22.65px;
  text-align: left;
  color: var(--text-color-main);
}

.employees-table-container td {
  font-size: 13.59px;
  font-weight: 400;
  line-height: 20.39px;
  text-align: left;
  color: var(--text-color-main);
}

.employees-table-container span {
  cursor: pointer;
}

.status {
  width: 126px;
  min-width: 126px;
  max-width: 126px;
  height: 39px;
  border-radius: 10px;
  background: #d91c3b;
  font-size: 14px;
  font-weight: 500;
  line-height: 18.2px;
  text-align: center;
  color: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
}

.employee-options {
  position: relative;
  top: 0;
  left: 875%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: var(--bg-dark-gray);
  padding: 15px 15px 15px 5px;
  border-radius: 10px;
  width: 100px;
  height: 60px;
}

.employee-options div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  width: 100%;
  border-radius: 10px;
  padding: 5px;
}

.employee-options div:hover {
  background-color: #cacaca;
}

.employee-options div,
.employee-options div img,
.employee-options div label {
  color: var(--text-color-main);
  cursor: pointer;
}

.employee-search {
  background-color: #fafafa;
  border-radius: 18px;
  padding: 10px 15px;
}

.employee-search input {
  background: transparent;
  border: none;
}

/* Estilos para dispositivos móviles */
@media (max-width: 480px) {
  .employees-button-container,
  .employees-table-container {
    display: none;
  }

  .employee-card-container {
    padding: 35px 29px;
    display: flex;
    flex-direction: column;
    gap: 20px 0px;
    overflow-y: scroll;
  }

  .employee-card {
    width: 315px;
    height: 173px;
    border-radius: 10px;
    background: var(--bg-dark-gray);
    padding: 18px 25px;
  }

  .employee-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .employee-card-header > div {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr)) 12px repeat(
        10,
        minmax(0, 1fr)
      );
    grid-template-rows: repeat(2, 1fr);
    justify-content: center;
    align-items: center;
  }

  .employee-card-header > div > div {
    grid-row: span 2;
    grid-column: span 2;
    width: 42px;
    height: 42px;
    background: #d9d9d9;
    border-radius: 50%;
  }

  .employee-card-header > div h4,
  .employee-card-header > div p {
    color: var(--text-color-main);
    grid-row: span 1;
    grid-column: 4 / -1;
    color: var(--text-color-main);
  }

  .employee-card-header > div h4 {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
  }

  .employee-card-header > div p {
    color: var(--text-color-main);
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
  }

  .employee-card-data-body {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr)) 4px repeat(
        9,
        minmax(0, 1fr)
      );
    gap: 9px 0px;
    position: relative;
    top: 15px;
  }

  .employee-card-data-body label {
    color: var(--text-color-main);
    grid-column: span 3;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
  }

  .employee-card-data-body p {
    color: var(--text-color-main);
    grid-column: 5 / -1;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
  }
}

/* Estilos para tabletas */
@media (min-width: 481px) and (max-width: 1024px) {
  .edit-status-delete {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
  }

  .edit-status-delete span {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
  }

  .id-employee {
    width: 67px;
  }

  .trip-employee {
    width: 150px;
    min-width: 150px;
    max-width: 150px;
    padding-right: 25px;
  }

  .phone-employee {
    width: 100px;
  }

  .name-employee-table {
    width: 120px;
  }

  .employee-card-container {
    display: none;
  }
}

/* Estilos para escritorios */
@media (min-width: 1025px) {
  .employee-card-container {
    display: none;
  }

  .edit-status-delete,
  .edit-status-delete span {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-right: 5px;
  }

  .edit-status-delete {
    gap: 50px;
    position: relative;
  }

  .edit-status-delete span {
    gap: 0px 30px;
  }

  .option-box {
    width: 160px;
    max-height: 200px;
    border-radius: 15.17px;
    background: var(--option-box-background-color);
    font-size: 16.18px;
    font-weight: 400;
    line-height: 24.27px;
    text-align: left;
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: absolute;
    top: 70%;
    right: 170px;
    transform: translateY(10px);
    z-index: 10;
  }

  .option-box section {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    padding: 10px;
    width: 85%;
  }

  .option-box section,
  .option-box img,
  .option-box label {
    color: var(--text-color-main);
    cursor: pointer;
  }

  .option-box label {
    text-align: left;
  }

  .option-box img {
    width: 20px;
    height: 20px;
  }

  .option-box section:nth-child(2) {
    border-bottom: 1px solid #e4e4e4;
    border-top: 1px solid #e4e4e4;
  }

  .option-box section:nth-child(3) {
    border-bottom: 1px solid #e4e4e4;
  }
}
