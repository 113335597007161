.view-payment-detail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.detail-plan-to-pay {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 50px;
  margin-top: 50px;
}

.plan-to-pay {
  background-color: var(--background-step-two);
  padding: 20px 25px 20px 20px;
  max-width: 300px;
  border-radius: 12px;
}

.plan-to-pay h4 {
  font-size: 45px;
  font-weight: bold;
  line-height: 65px;
  color: var(--text-color-main);
}

.plan-to-pay label {
  font-size: 18px;
  font-weight: bold;
  color: var(--text-color-main);
}

.plan-to-pay li {
  color: var(--text-color-main);
}

.payment-details {
  display: flex;
  flex-direction: column;
  gap: 200px;
  width: 100%;
}

.detail-and-price,
.payment-method {
  display: flex;
  flex-direction: column;
}

.detail-and-price>label,
.cost-and-plan-name label:nth-child(2) {
  font-size: 18px;
  font-weight: 600;
  color: var(--text-color-main);
}

.cost-and-plan-name {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.monthly-payment,
.continue-with-payment {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.payment-method>label {
  font-size: 20px;
  font-weight: 600;
  color: var(--text-color-main);
}

.payment-method>button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: none;
  border-radius: 12px;
  border: var(--green) 3px solid;
  font-size: 18px;
  font-weight: 500;
  margin: 10px 0px 20px;
  color: var(--text-color-main);
}

.continue-with-payment button {
  background-color: var(--green);
  color: #fafafa;
  border: none;
  font-size: 18px;
  font-weight: 800;
  width: 300px;
  padding: 5px 0px;
  border-radius: 12px;
}

.monthly-payment span,
.cost-and-plan-name>label {
  color: var(--text-color-main);
}

/* Estilos para dispositivos móviles */
@media (max-width: 480px) {
  .view-payment-detail {
    display: none;
  }

  .mobile-view-purchase {
    padding: 30px;
    height: 85vh;
  }

  .plan-card-for-payment-detail {
    margin: 20px 0px;
  }

  .name-and-price-of-the-plan {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--background-step-two);
    border-radius: 18px 18px 0px 0px;
  }

  .name-and-price-of-the-plan>label {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    color: var(--text-color-main);
    position: relative;
    bottom: -20px;
  }

  .plan-price-per-month {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: baseline;
  }

  .plan-price-per-month>label {
    font-size: 80px;
    font-weight: bold;
    color: var(--text-color-main);
  }

  .plan-price-per-month span {
    font-weight: bold;
    color: var(--text-color-main);
  }

  .subscription-plan-details {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 20px;
    background-color: var(--background-step);
    border-radius: 0px 0px 18px 18px;
  }

  .subscription-plan-details>label {
    font-size: 18px;
    font-weight: bold;
    color: var(--text-color-main);
  }

  .subscription-plan-details ul {
    color: var(--text-color-main);
  }

  .payment-method-section-in-subscription {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
  }

  .payment-method-section-in-subscription>label {
    font-size: 26px;
    font-weight: 600;
    color: var(--text-color-main);
    margin: 15px 15px 0px;
  }

  .payment-method-selection-button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: none;
    border: var(--green) 2px solid;
    color: var(--text-color-main);
    padding: 10px;
    font-size: 18px;
    font-weight: 600;
    border-radius: 12px;
  }

  .container-for-the-continue-payment-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    bottom: -100px;
  }
}

/* Estilos para tabletas */
@media (min-width: 481px) and (max-width: 1024px) {
  .mobile-view-purchase {
    display: none;
  }
}

/* Estilos para escritorios */
@media (min-width: 1025px) {
  .mobile-view-purchase {
    display: none;
  }
}