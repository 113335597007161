.container-register-employee {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.employee-photo-register-container {
  grid-column-start: 5;
  grid-column-end: 9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.employee-photo-register-container > div {
  background-color: #d9d9d9;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 7.2px solid var(--green);
}

.employee-photo-register-container label,
.employee-photo-register-container span {
  font-size: 25px;
  font-weight: 800;
  line-height: 37.5px;
  text-align: center;
  color: var(--text-color-main);
  margin-top: 15px;
}

.employee-registration-form-container {
  display: grid;
  grid-column-start: 4;
  grid-column-end: 10;
  margin-top: 30px;
}

.employee-registration-form-container form {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.employee-registration-form-container form div {
  grid-column: span 6;
}

.employee-registration-form-container form div:nth-child(1),
.employee-registration-form-container form div:nth-child(3) {
  margin-right: 30px;
}

.employee-registration-form-container form div:nth-child(2),
.employee-registration-form-container form div:nth-child(4) {
  margin-left: 30px;
}

.employee-registration-form-container form div:nth-child(5),
.employee-registration-form-container form div:nth-child(6) {
  grid-column: span 12;
}

.employee-registration-form-container form div:nth-child(6) {
  margin-top: 50px;
}

.employee-registration-form-container form div label {
  color: var(--text-color-main);
  font-size: 22px;
  font-weight: 700;
  line-height: 33px;
  text-align: left;
  color: var(--text-color-main);
}

.employee-registration-form-container form div input {
  width: 100%;
  height: 30px;
  background-color: #eeeeee;
  border: none;
  border-radius: 10px;
  padding: 5px 10px;
}

.employee-registration-form-container form div button {
  width: 247px;
  height: 60px;
  font-size: 23px;
  font-weight: 800;
  line-height: 34.5px;
  text-align: center;
  border: none;
  border-radius: 20px;
}

.form-group-employee-registration {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 10px;
}

.employee-registration-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.employee-registration-button-container button:nth-child(1) {
  background-color: var(--bg-black);
  color: #fafafa;
}

.employee-registration-button-container button:nth-child(2) {
  background-color: var(--green);
  color: var(--text-color-main);
}

/* Estilos para dispositivos móviles */
@media (max-width: 480px) {
  .employee-photo-register-container,
  .employee-registration-form-container,
  .employee-registration-form-container form div {
    grid-column: span 12;
  }

  .employee-registration-button-container {
    flex-direction: column;
  }

  .employee-registration-form-container form {
    gap: 25px 0px;
    margin-top: 25px;
    margin-bottom: 25px;
  }

  .employee-registration-form-container form div:nth-child(6) {
    grid-column: span 12;
  }

  .employee-registration-form-container form div button {
    width: 100%;
    height: 50px;
    border: none;
    border-radius: 10px;
    font-weight: 900;
    font-size: 16px;
  }

  .employee-registration-button-container {
    gap: 25px;
  }

  .employee-registration-form-container {
    margin: 0px 25px;
  }

  .employee-registration-form-container form div input {
    width: 95%;
  }

  .employee-photo-register-container {
    margin-top: 30px;
  }
}

/* Estilos para tabletas */
@media (min-width: 481px) and (max-width: 1024px) {
  .employee-photo-register-container > div {
    width: 150px;
    height: 150px;
  }

  .employee-registration-form-container {
    grid-column-start: 3;
    grid-column-end: 11;
  }
}

/* Estilos para escritorios */
@media (min-width: 1025px) {
}
