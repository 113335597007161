.plataform-footer {
  border-top: var(--footer-border-top);
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--footer-height);
  background-color: #000;
  color: #fff;
}

/* Consultas de medios */
@media (max-width: 480px) {
}

@media (min-width: 481px) and (max-width: 1024px) {
}

@media (min-width: 1025px) {
}
